import { CancelToken } from "axios";
import { actionChannel, take, fork, call, put } from "redux-saga/effects";
import getAxios from "./axiosAPI";
import history from '../history/history';
import { toast } from 'react-toastify';
import * as utils from '../../Utils';
import _ from "lodash";

const pendingRequests = {};

const similarPendingRequestExist = (actionType, url) =>
  pendingRequests[actionType] && pendingRequests[actionType].url === url;

function* invokeAPI(action) {
  const { payload } = action;
  const {
    method,
    url,
    data,
    apiConfig,
    action: actionType,
    resolve,
    reject,
    isLoader,
  } = payload;
  try {
    
    let response = {};

    if (isLoader) {
      utils.showLoader();
    }
    const api = getAxios();
    switch (method) {
      case "GET": {
        if (similarPendingRequestExist(actionType, url)) {
          throw new Error("Similar axios request detected!");
        } else {
          const source = CancelToken.source();
          const cancelToken = source.token;
          pendingRequests[actionType] = { url, api, source };

          response = yield call([api, api.get], url, {
            ...apiConfig,
            cancelToken,
          });
        }
        break;
      }
      case "POST":
        response = yield call([api, api.post], url, data, { ...apiConfig });
        break;

      case "PUT":
        response = yield call([api, api.put], url, data, { ...apiConfig });
        break;

      case "PATCH":
        response = yield call([api, api.patch], url, data, { ...apiConfig });
        break;

      case "DELETE":
        response = yield call([api, api.delete], url, { ...apiConfig });
        break;

      case "EMPTY":
        response = { status: 200, data: { status: 200 } }
        break;

      default:
        throw new Error(`API method ${method} is not supported!`);
    }

    if (response.status === Number(200)
      || response.status === Number(201)
      || response.status === Number(201)
    ) {
      // console.log(response);
      // if(!response.data.data){
      //   response.data = {...response.data,data:[]}
      // }
      yield* dispatchFulfilled(action, response.data);

      // yield put(showToastMessage({ message: _get(response, 'data.message', ''), type: _get(response, 'data.type', '') }));
    } else if (response.status === Number(401)) {console.log(response.status)
      if ((new URL(window.location.href)).pathname.indexOf("login") == -1) {
        sessionStorage.clear();
        toast.error(`Session Timed out! Please login again`);
        yield call(forwardTo, '/');
      }

      yield* dispatchRejected(payload.action, action, "Internal server error");
      // yield put(showToastMessage({ message: _get(response, 'data.message', ''), type: _get(response, 'data.type', '') }));
    } else {
      yield* dispatchRejected(payload.action, action, "Internal server error");
      //yield put(showToastMessage({ message: 'Session Timed out! Please login again' }));
    }
    delete pendingRequests[actionType];

    if (_.isEmpty(pendingRequests)) {
      utils.hideLoader();
    }
    if (resolve) {
      //response = {...response,apiStatusCode:response.status}
      const status = (response && response.status)?response.status:200;
      resolve(response.data,status);
    }
  } catch (error) {
    
    delete pendingRequests[actionType];
    if (_.isEmpty(pendingRequests)) {
      utils.hideLoader();
    }

    // if (error && error.message === "Network Error") {

    //   if ((new URL(window.location.href)).pathname.indexOf("login") == -1) {
    //     // yield put({ type: `LOGOUT`, payload: error.response });
    //     sessionStorage.clear();
    //     window.location.href = "/";
    //   }
    //   // return;
    // }
    if (error.response && error.response.status === 401) {

      if ((new URL(window.location.href)).pathname.indexOf("login") == -1) {
        // yield put({ type: `LOGOUT`, payload: error.response });
        sessionStorage.clear();
        window.location.href = "/login";
      }
      // return;
    }

    if (error && error.status === 401) {
      // yield put({ type: `LOGOUT`, payload: error.response });
      if ((new URL(window.location.href)).pathname.indexOf("login") == -1) {
        sessionStorage.clear();
        window.location.href = "/";
      }
      // return;
    }
    yield* dispatchFulfilled(action, { status: 400, data: null });
    if (reject) {
      reject(error,error.response?error.response.status:error);
    }
  } finally {
    // yield put(setStopLoader());
  }
}

function forwardTo(location) {
  history.push(location);
}

function* dispatchFulfilled(action, response) {
  yield put({ type: `${action.payload.action}_SUCCESS`, payload: response });
}

function* dispatchRejected(actionType, action, error) {
  yield put({
    type: `${actionType}_ERROR`,
    actualAction: action,
    payload: { response: error },
  });
}

function* apiSaga() {
  const actionQueue = yield actionChannel("API_INVOCATION");
  while (true) {
    const action = yield take(actionQueue);
    yield fork(invokeAPI, action);
  }
}

export { invokeAPI };
export default apiSaga;
