/* eslint-disable react/no-multi-comp */
/* eslint-disable react/display-name */
import React, { lazy } from "react";
import { Redirect } from "react-router-dom";
import AuthenticatedLayout from "../Layouts/Authenticated";
import AuthenticatedChildLayout from "../Layouts/AuthenticatedChild";
import GuestLayout from "../Layouts/Guest";
import { getToken } from "../Utils/token";

const routes = [

  {
    route: "*",
    component: GuestLayout,

    routes: [
      {
        path: "/",
        exact: true,
        component: lazy(() => import("../View/pre-login/ParentView")),
        isPrivate: false,

      },
      {
        path: "/proxy",
        exact: true,
        component: lazy(() => import("../View/pre-login/ParentView/Q2")),
        isPrivate: false,

      },
      {
        path: "/home",
        exact: true,
        component: lazy(() => import("../View/pre-login/ParentView/Login")),
        isPrivate: false,

      },
      {
        path: "/index",
        exact: true,
        component: lazy(() => import("../View/pre-login/ParentView")),
        isPrivate: false,

      },
      {
        path: "/welcome",
        exact: true,
        component: lazy(() => import("../View/pre-login/ParentView/Landing")),
        isPrivate: false,

      },
      {
        path: "/banno",
        exact: true,
        component: lazy(() => import("../View/pre-login/ParentView/Banno")),
        isPrivate: false,

      },
      {
        path: "/dynamic",
        exact: true,
        component: lazy(() => import("../View/pre-login/ParentView/Banno")),
        isPrivate: false,

      },
      {
        path: "/application3.html",
        exact: true,
        component: () => window.location.href = "/bank.html",
        isPrivate: false,

      },
      {
        path: "/login",
        exact: true,
        component: lazy(() => import("../View/pre-login/ParentView/Login")),
        isPrivate: false,

      },
      {
        path: "/login/authorize/2fa",
        exact: true,
        component: lazy(() => import("../View/pre-login/ParentView/2fa")),
        isPrivate: false,
        isAuth: true,
      },
      {
        path: "/complete/profile",
        exact: true,
        component: lazy(() => import("../View/post-login/ParentView/ProfileComplete")),
        isPrivate: false,
      },
      {
        path: "/privacy-policy",
        exact: true,
        component: lazy(() => import("../View/pre-login/ParentView/PrivacyPolicy")),
        isPrivate: false,
      },
      {
        path: "/bank-tab1",
        exact: true,
        component: lazy(() => import("../View/pre-login/ParentView/BankFrame1")),
        isPrivate: false,
      },
      {
        path: "/bank-tab2",
        exact: true,
        component: lazy(() => import("../View/pre-login/ParentView/BankFrame2")),
        isPrivate: false,
      },
      {
        path: "/bank-tab3",
        exact: true,
        component: lazy(() => import("../View/pre-login/ParentView/BankFrame3")),
        isPrivate: false,
      },
      {
        path: "/bank-tab4",
        exact: true,
        component: lazy(() => import("../View/pre-login/ParentView/BankFrame4")),
        isPrivate: false,
      },
      {
        path: "/bank-tab5",
        exact: true,
        component: lazy(() => import("../View/pre-login/ParentView/BankFrame5")),
        isPrivate: false,
      },
      {
        path: "/bank-tab6",
        exact: true,
        component: lazy(() => import("../View/pre-login/ParentView/BankFrame6")),
        isPrivate: false,
      },
      {
        path: "/promotions",
        exact: true,
        component: lazy(() => import("../View/pre-login/ParentView/Promotions")),
        isPrivate: false,
      },
      {
        path: "/sso/login",
        exact: true,
        component: lazy(() => import("../View/pre-login/ParentView/SSOLogin")),
        isPrivate: false,

      },
      {
        path: "/register",
        exact: true,
        component: lazy(() => import("../View/pre-login/ParentView/Register")),
        isPrivate: false,
      },
      {
        path: "/landing",
        exact: true,
        component: lazy(() => import("../View/pre-login/ParentView/Landing")),
        isPrivate: false,
        params: {
          privateRegister: true
        }
      },
      {
        path: "/welcome/landing",
        exact: true,
        component: lazy(() => import("../View/pre-login/ParentView/Landing")),
        isPrivate: false,
        params: {
          privateRegister: false
        }
      },
      {
        path: "/accept-invite",
        exact: true,
        component: lazy(() => import("../View/pre-login/ParentView/Landing")),
        isPrivate: false,
        params: {
          privateRegister: false
        }
      },
      {
        path: "/forgot/password",
        exact: true,
        component: lazy(() => import("../View/pre-login/ParentView/Forgot/ResetPassword")),
        isPrivate: false,

      },
      {
        path: "/change/password",
        exact: true,
        component: lazy(() => import("../View/pre-login/ParentView/Forgot/ChangePassword")),
        isPrivate: false,

      },
      {
        path: "/set-password",
        exact: true,
        component: lazy(() => import("../View/pre-login/ParentView/Forgot/ChangePassword")),
        isPrivate: false,

      },
      {
        path: "/chore-check/register",
        exact: true,
        component: lazy(() => import("../vendor/ChoreCheck/Register")),
        isPrivate: false,
      },
      {
        path: "/chore-check/greatNews",
        exact: true,
        component: lazy(() => import("../vendor/ChoreCheck/GreatNews")),
        isPrivate: false,
      },
      {
        path: "/chore-check/userAgreement",
        exact: true,
        component: lazy(() => import("../vendor/ChoreCheck/UserAgreement")),
        isPrivate: false,
      },
      {
        path: "/chore-check/signIn",
        exact: true,
        component: lazy(() => import("../vendor/ChoreCheck/SignInView4")),
        isPrivate: false,
      },
      {
        path: "/chore-check/createPass",
        exact: true,
        component: lazy(() => import("../vendor/ChoreCheck/CreatePasswordView5")),
        isPrivate: false,
      },
      {
        path: "/chore-check/personalInfo",
        exact: true,
        component: lazy(() => import("../vendor/ChoreCheck/PersonalInfoView6")),
        isPrivate: false,
      },
      {
        path: "/chore-check/verifyPhone",
        exact: true,
        component: lazy(() => import("../vendor/ChoreCheck/VerifyPhone")),
        isPrivate: false,
      },
      {
        path: "/chore-check/verifyPhoneCode",
        exact: true,
        component: lazy(() => import("../vendor/ChoreCheck/VerifyPhoneCode")),
        isPrivate: false,
      },
      {
        path: "/chore-check/verifyIdentity",
        exact: true,
        component: lazy(() => import("../vendor/ChoreCheck/VerifyIdentity")),
        isPrivate: false,
      },
      {
        path: "/chore-check/conversionOption",
        exact: true,
        component: lazy(() => import("../vendor/ChoreCheck/ConversionOption")),
        isPrivate: false,
      },
      {
        path: "/chore-check/migrateChildren",
        exact: true,
        component: lazy(() => import("../vendor/ChoreCheck/MigrateChildren")),
        isPrivate: false,
      },
      {
        path: "/chore-check/migrateChildren",
        exact: true,
        component: lazy(() => import("../vendor/ChoreCheck/MigrateChildren")),
        isPrivate: false,
      },
      {
        path: "/chore-check/installMazoola",
        exact: true,
        component: lazy(() => import("../vendor/ChoreCheck/InstallMazoola")),
        isPrivate: false,
      },
      {
        path: "/chore-check/inviteChildrenToMazoola",
        exact: true,
        component: lazy(() => import("../vendor/ChoreCheck/InviteChildren")),
        isPrivate: false,
      },
      {
        path: "/bill-pay",
        exact: true,
        component: lazy(() => import("../View/post-login/ParentView/Education")),
        isPrivate: false,
        isAuth: true,
        isMobileNav: false,
        params: {
          title: 'education',
          notificationBell: false,
          helpIcon: false,
          languageIcon: false,
        }
      },
      {
        path: "/diagnostics",
        exact: true,
        component: lazy(() => import("../View/post-login/ParentView/Diagnostics")),
        isPrivate: false,
        isAuth: true,
        isMobileNav: false,
        params: {
          title: 'diagnostic',
          notificationBell: false,
          helpIcon: false,
          languageIcon: false,
        }
      },
      {
        path: "/parent/",
        exact: false,
        component: AuthenticatedLayout,
        isPrivate: false,
        isAuth: true,
        isMobileNav: false,
        params: {
          title: 'parental_superpowers',
          notificationBell: false,
          helpIcon: false,
          languageIcon: false,
        },
        routes: [
          {
            path: "/parent/dashboard",
            exact: true,
            component: lazy(() => import("../View/post-login/ParentView/Dashboard")),
            isPrivate: false,
            isAuth: true,
            isMobileNav: true,
            params: {
              notificationBell: false,
              helpIcon: true,
              languageIcon: true,
              superpowerIcon : true,
              allowInvestOnlyToggle : true
            }
          },
          {
            path: "/parent/diagnostics",
            exact: true,
            component: lazy(() => import("../View/post-login/ParentView/Diagnostics")),
            isPrivate: false,
            isAuth: true,
            isMobileNav: true,
            params: {
              notificationBell: false,
              helpIcon: false,
              languageIcon: false,
              superpowerIcon : false,
              allowInvestOnlyToggle : false
            }
          },
          {
            path: "/parent/child/card",
            exact: true,
            component: lazy(() => import("../View/post-login/ParentView/ChildCard")),
            isPrivate: false,
            isAuth: true,
            isMobileNav: false,
            params: {
              notificationBell: false,
              helpIcon: false,
              languageIcon: false,
              superpowerIcon : false,
              sidebar: false,
            }
          },
          {
            path: "/parent/superpowers",
            exact: true,
            component: lazy(() => import("../View/post-login/ParentView/SuperPowers")),
            isPrivate: false,
            isAuth: true,
            isMobileNav: false,
            params: {
              title: 'parental_superpowers',
              notificationBell: false,
              helpIcon: false,
              languageIcon: false,
              superpowerIcon : false
            }
          },
          
          {
            path: "/parent/profile",
            exact: true,
            component: lazy(() => import("../View/post-login/ParentView/EditProfile")),
            isPrivate: false,
            isAuth: true,
            isMobileNav: false,
            params: {
              title: 'my_profile',
              notificationBell: false,
              helpIcon: true,
              languageIcon: false,
            }
          },
          {
            path: "/parent/investmentUrl/profile",
            exact: true,
            component: lazy(() => import("../View/post-login/ParentView/EditProfile")),
            isPrivate: false,
            isAuth: true,
            isMobileNav: false,
            params: {
              title: 'my_profile',
              notificationBell: false,
              helpIcon: true,
              languageIcon: false,
              sidebar: false,
              investmentSidebar: true
            }
          },
          {
            path: "/parent/trustedContact",
            exact: true,
            component: lazy(() => import("../View/post-login/ParentView/TrustedContact")),
            isPrivate: false,
            isAuth: true,
            isMobileNav: false,
            params: {
              title: 'trusted_contact',
              notificationBell: false,
              helpIcon: false,
              languageIcon: false,
            }
          },
          {
            path: "/parent/child/setting",
            exact: true,
            component: lazy(() => import("../View/post-login/ParentView/ChildSetting")),
            isPrivate: false,
            isAuth: true,
            isMobileNav: false,
            params: {
              title: '',
              notificationBell: false,
              helpIcon: false,
              languageIcon: false,
            }
          },
          {
            path: "/parent/education",
            exact: true,
            component: lazy(() => import("../View/post-login/ParentView/Education")),
            isPrivate: false,
            isAuth: true,
            isMobileNav: true,
            params: {
              title: 'education',
              notificationBell: false,
              helpIcon: true,
              languageIcon: false,
            }
          },
          {
            path: "/parent/education/detail/:contentId",
            exact: true,
            component: lazy(() => import("../View/post-login/ParentView/EducationDetail")),
            isPrivate: false,
            isAuth: true,
            isMobileNav: false,
            params: {
              title: '',
              notificationBell: false,
              helpIcon: false,
              languageIcon: false,
            }
          },

          {
            path: "/parent/shopping",
            exact: true,
            component: lazy(() => import("../View/post-login/ParentView/Shopping")),
            isPrivate: false,
            isAuth: true,
            isMobileNav: true,
            params: {
              title: '',
              notificationBell: false,
              helpIcon: false,
              languageIcon: false,
            }
          },
          {
            path: "/parent/timeline",
            exact: true,
            component: lazy(() => import("../View/post-login/ParentView/Timeline")),
            isPrivate: false,
            isAuth: true,
            isMobileNav: true,
            params: {
              title: 'timeline',
              notificationBell: false,
              helpIcon: false,
              languageIcon: false,
            }
          },
          {
            path: "/parent/createDispute",
            exact: true,
            component: lazy(() => import("../View/post-login/ParentView/Timeline/CreateDispute")),
            isPrivate: false,
            isAuth: true,
            isMobileNav: false,
            params: {
              title: 'create_dispute',
              notificationBell: false,
              helpIcon: false,
              languageIcon: false,
            }
          },
          {
            path: "/parent/help",
            exact: true,
            component: lazy(() => import("../View/pre-login/ParentView/Help")),
            isPrivate: false,
            params: {
              title: 'help',
              notificationBell: false,
              helpIcon: false,
              languageIcon: false,
            }
          },
          {
            path: "/parent/wallet",
            exact: true,
            component: lazy(() => import("../View/post-login/ParentView/Wallet")),
            isPrivate: false,
            isAuth: true,
            isMobileNav: true,
            params: {
              title: 'wallet',
              notificationBell: false,
              helpIcon: true,
              languageIcon: false,
            }
          },
          {
            path: "/parent/statement",
            exact: true,
            component: lazy(() => import("../View/post-login/ParentView/Statement")),
            isPrivate: false,
            isAuth: true,
            isMobileNav: false,
            params: {
              title: 'statements',
              notificationBell: false,
              helpIcon: false,
              languageIcon: false,
            }
          },
          {
            path: "/parent/scheduledTransfers",
            exact: true,
            component: lazy(() => import("../View/post-login/ParentView/ScheduleTransfer")),
            isPrivate: false,
            isAuth: true,
            isMobileNav: false,
            params: {
              title: 'scheduled_transfer',
              notificationBell: false,
              helpIcon: false,
              languageIcon: false,
            }
          },
          {
            path: "/parent/receipts",
            exact: true,
            component: lazy(() => import("../View/post-login/ParentView/Receipt")),
            isPrivate: false,
            isAuth: true,
            isMobileNav: false,
            params: {
              title: 'receipts',
              notificationBell: false,
              helpIcon: false,
              languageIcon: false,
            }
          },
          {
            path: "/parent/child/add",
            exact: true,
            component: lazy(() => import("../View/post-login/ParentView/AddChild")),
            isPrivate: false,
            isAuth: true,
            isMobileNav: false,
            params: {
              title: '',
              notificationBell: false,
              helpIcon: false,
              languageIcon: false,
            }
          },
          {
            path: "/parent/child/add/:type",
            exact: true,
            component: lazy(() => import("../View/post-login/ParentView/AddChild")),
            isPrivate: false,
            isAuth: true,
            isMobileNav: false,
            params: {
              title: '',
              notificationBell: false,
              helpIcon: false,
              languageIcon: false,
            }
          },
          {
            path: "/parent/child/detail",
            exact: true,
            component: lazy(() => import("../View/post-login/ParentView/ChildDetail")),
            isPrivate: false,
            isAuth: true,
            isMobileNav: false,
            params: {
              title: "",
              notificationBell: false,
              helpIcon: false,
              languageIcon: false,
            }
          },
          {
            path: "/parent/child/detail/:childId",
            exact: true,
            component: lazy(() => import("../View/post-login/ParentView/ChildDetail")),
            isPrivate: false,
            isAuth:true,
           isMobileNav:false,
            params:{
              title:"",
              notificationBell:false,
            }
          },
          {
            path: "/parent/chore/:type",
            exact: true,
            component: lazy(() => import("../View/post-login/ParentView/AddChore")),
            isPrivate: false,
            isAuth: true,
            isMobileNav: false,
            params: {
              // title:'Add Chore',
              notificationBell: false,
              helpIcon: false,
              languageIcon: false,
            }
          },
          {
            path: "/parent/chore/common/add",
            exact: true,
            component: lazy(() => import("../View/post-login/ParentView/AddChoreCommon")),
            isPrivate: false,
            isAuth: true,
            isMobileNav: false,
            params: {
              // title:'Add Chore',
              notificationBell: false,
              helpIcon: false,
              languageIcon: false,
            }
          },
          {
            path: "/parent/chore/edit/:choreId",
            exact: true,
            component: lazy(() => import("../View/post-login/ParentView/EditChore")),
            isPrivate: false,
            isAuth: true,
            isMobileNav: false,
            params: {
              // title:'Edit Chore',
              notificationBell: false,
              helpIcon: false,
              languageIcon: false,
            }
          },
          {
            path: "/parent/chore/:type/:choreId",
            exact: true,
            component: lazy(() => import("../View/post-login/ParentView/EditChore")),
            isPrivate: false,
            isAuth: true,
            isMobileNav: false,
            params: {
              // title:'Edit Chore',
              notificationBell: false,
              helpIcon: false,
              languageIcon: false,
            }
          },
          {
            path: "/parent/chore/common/edit/:choreId",
            exact: true,
            component: lazy(() => import("../View/post-login/ParentView/AddChoreCommon")),
            isPrivate: false,
            isAuth: true,
            isMobileNav: false,
            params: {
              // title:'Edit Chore',
              notificationBell: false,
              helpIcon: false,
              languageIcon: false,
            }
          },
          {
            path: "/parent/banking",
            exact: true,
            component: lazy(() => import("../View/post-login/ParentView/Setting")),
            isPrivate: false,
            isAuth: true,
            isMobileNav: false,
            params: {
              title: 'banking',
              notificationBell: false,
              helpIcon: false,
              languageIcon: false,
            }
          },
          {
            path: "/parent/setting/dashboard",
            exact: true,
            component: lazy(() => import("../View/post-login/ParentView/Setting")),
            isPrivate: false,
            isAuth: true,
            isMobileNav: false,
            params: {
              title: 'settings',
              notificationBell: false,
              helpIcon: false,
              languageIcon: false,
            }
          },
          {
            path: "/parent/investmentUrl/banking",
            exact: true,
            component: lazy(() => import("../View/post-login/ParentView/Setting")),
            isPrivate: false,
            isAuth: true,
            isMobileNav: false,
            params: {
              title: 'banking',
              notificationBell: false,
              helpIcon: false,
              languageIcon: false,
              sidebar: false,
              investmentSidebar: true
            }
          },
          {
            path: "/parent/investmentUrl/notifications",
            exact: true,
            component: lazy(() => import("../View/post-login/ParentView/Setting")),
            isPrivate: false,
            isAuth: true,
            isMobileNav: false,
            params: {
              title: 'notifications',
              notificationBell: false,
              helpIcon: false,
              languageIcon: false,
              sidebar: false,
              investmentSidebar: true
            }
          },
          {
            path: "/parent/investmentUrl/help",
            exact: true,
            component: lazy(() => import("../View/post-login/ParentView/Setting")),
            isPrivate: false,
            isAuth: true,
            isMobileNav: false,
            params: {
              title: 'help',
              notificationBell: false,
              helpIcon: false,
              languageIcon: false,
              sidebar: false,
              investmentSidebar: true
            }
          },
          {
            path: "/parent/investmentUrl/legal_notices",
            exact: true,
            component: lazy(() => import("../View/post-login/ParentView/Setting")),
            isPrivate: false,
            isAuth: true,
            isMobileNav: false,
            params: {
              title: 'legal_notices',
              notificationBell: false,
              helpIcon: false,
              languageIcon: false,
              sidebar: false,
              investmentSidebar: true
            }
          },
          {
            path: "/parent/investmentUrl/accessibility",
            exact: true,
            component: lazy(() => import("../View/post-login/ParentView/Setting")),
            isPrivate: false,
            isAuth: true,
            isMobileNav: false,
            params: {
              title: 'accessibility',
              notificationBell: false,
              helpIcon: false,
              languageIcon: false,
              sidebar: false,
              investmentSidebar: true
            }
          },
          {
            path: "/parent/changePassword",
            exact: true,
            component: lazy(() => import("../View/post-login/ParentView/Setting/ChangePassword")),
            isPrivate: false,
            isAuth: true,
            isMobileNav: false,
            params: {
              title: 'change_password',
              notificationBell: false,
              helpIcon: false,
              languageIcon: false,
            }
          },

          {
            path: "/parent/child/changePassword",
            exact: true,
            component: lazy(() => import("../View/post-login/ParentView/Setting/ChangePassword/Child")),
            isPrivate: false,
            isAuth: true,
            isMobileNav: false,
            params: {
              title: 'change_password',
              notificationBell: false,
              helpIcon: false,
              languageIcon: false,
            }
          },
          // {
          //   path: "/parent/invite",
          //   exact: true,
          //   component: lazy(() => import("../View/post-login/ParentView/Setting/Invite")),
          //   isPrivate: false,
          //   isAuth: true,
          //   isMobileNav: false,
          //   params: {
          //     title: "",
          //     notificationBell: false,
          //     helpIcon: false,
          //     languageIcon: false,
          //   }
          // },
          {
            path: "/parent/digital/goals",
            exact: true,
            component: lazy(() => import("../View/post-login/ParentView/DigitalWallet/ChildDigitalGoals")),
            isPrivate: false,
            isAuth: true,
            isMobileNav: false,
            params: {
              title: 'goals',
              notificationBell: false,
              helpIcon: false,
              languageIcon: false,
            }
          },
          {
            path: "/parent/digital/wallet",
            exact: true,
            component: lazy(() => import("../View/post-login/ParentView/DigitalWallet/ChildDigitalWallet")),
            isPrivate: false,
            isAuth: true,
            isMobileNav: false,
            params: {
              title: 'wallet',
              notificationBell: false,
              helpIcon: false,
              languageIcon: false,
            }
          },

          {
            path: "/parent/digital/saving",
            exact: true,
            component: lazy(() => import("../View/post-login/ParentView/DigitalWallet/ChildDigitalSaving")),
            isPrivate: false,
            isAuth: true,
            isMobileNav: false,
            params: {
              title: 'savings',
              notificationBell: false,
              helpIcon: false,
              languageIcon: false,
            }
          },

          {
            path: "/parent/investments",
            exact: true,
            component: lazy(() => import("../View/post-login/ParentView/Investments/Welcome")),
            isPrivate: false,
            isAuth: true,
            isMobileNav: false,
            params: {
              title: 'Investments',
              notificationBell: false,
              helpIcon: false,
              languageIcon: false,
              headerIcons: false,
              sidebar: false,
            }
          },

          {
            path: "/parent/investments/dashboard",
            exact: true,
            component: lazy(() => import("../View/post-login/ParentView/Investments/Parent/Dashboard")),
            isPrivate: false,
            isAuth: true,
            isMobileNav: true,
            params: {
              title: 'Investments',
              notificationBell: false,
              helpIcon: false,
              languageIcon: false,
              headerIcons: false,
              sidebar: false,
              investmentSidebar: true
            }
          },
          {
            path: "/parent/investments/timeline",
            exact: true,
            component: lazy(() => import("../View/post-login/ParentView/Investments/Parent/Timeline")),
            isPrivate: false,
            isAuth: true,
            isMobileNav: true,
            params: {
              title: 'investments_timeline',
              notificationBell: false,
              helpIcon: false,
              languageIcon: false,
              headerIcons: false,
              sidebar: false,
              investmentSidebar: true
            }
          },
          {
            path: "/parent/investments/portfolio-detail",
            exact: true,
            component: lazy(() => import("../View/post-login/ParentView/Investments/Parent/PortfolioDetail")),
            isPrivate: false,
            isAuth: true,
            isMobileNav: true,
            params: {
              notificationBell: false,
              helpIcon: false,
              languageIcon: false,
              headerIcons: false,
              sidebar: false,
              investmentSidebar: true
            }
          },
          {
            path: "/parent/investments/buy-assets",
            exact: true,
            component: lazy(() => import("../View/post-login/ParentView/Investments/Parent/BuyAssets")),
            isPrivate: false,
            isAuth: true,
            isMobileNav: true,
            params: {
              notificationBell: false,
              helpIcon: false,
              languageIcon: false,
              headerIcons: false,
              sidebar: false,
              investmentSidebar: true
            }
          },
          {
            path: "/parent/investments/research",
            exact: true,
            component: lazy(() => import("../View/post-login/ParentView/Investments/Parent/ResearchLanding")),
            isPrivate: false,
            isAuth: true,
            isMobileNav: true,
            params: {
              notificationBell: false,
              helpIcon: false,
              languageIcon: false,
              headerIcons: false,
              sidebar: false,
              investmentSidebar: true
            }
          },
          {
            path: "/parent/investments/research-industry",
            exact: true,
            component: lazy(() => import("../View/post-login/ParentView/Investments/Parent/ResearchIndividual")),
            isPrivate: false,
            isAuth: true,
            isMobileNav: true,
            params: {
              notificationBell: false,
              helpIcon: false,
              languageIcon: false,
              headerIcons: false,
              sidebar: false,
              investmentSidebar: true
            }
          },
          {
            path: "/parent/investments/watchlist",
            exact: true,
            component: lazy(() => import("../View/post-login/ParentView/Investments/Parent/Watchlist")),
            isPrivate: false,
            isAuth: true,
            isMobileNav: true,
            params: {
              notificationBell: false,
              helpIcon: false,
              languageIcon: false,
              headerIcons: false,
              sidebar: false,
              investmentSidebar: true
            }
          },
          {
            path: "/parent/investments/child/settings",
            exact: true,
            component: lazy(() => import("../View/post-login/ParentView/Investments/Settings")),
            isPrivate: false,
            isAuth: true,
            isMobileNav: false,
            params: {
              notificationBell: false,
              helpIcon: false,
              languageIcon: false,
              headerIcons: false,
              sidebar: false,
              investmentSidebar: false
            }
          },
          {
            path: "/parent/parentInvestmentSettings",
            exact: true,
            component: lazy(() => import("../View/post-login/ParentView/InvestmentParentSettings")),
            isPrivate: false,
            isAuth: true,
            isMobileNav: true,
            params: {
              notificationBell: false,
              helpIcon: false,
              languageIcon: false,
              headerIcons: false,
              sidebar: false,
              investmentSidebar: true
            }
          },
          {
            path: "/parent/parentInvestmentSettingList",
            exact: true,
            component: lazy(() => import("../View/post-login/ParentView/Investments/Parent/InvestmentSettingsList")),
            isPrivate: false,
            isAuth: true,
            isMobileNav: true,
            params: {
              notificationBell: false,
              helpIcon: false,
              languageIcon: false,
              headerIcons: false,
              sidebar: false,
              investmentSidebar: true
            }
          },
          {
            path: "/parent/investments/settings",
            exact: true,
            component: lazy(() => import("../View/post-login/ParentView/Investments/Parent/InvestmentSettings")),
            isPrivate: false,
            isAuth: true,
            isMobileNav: false,
            params: {
              notificationBell: false,
              helpIcon: false,
              languageIcon: false,
              headerIcons: false,
              sidebar: false,
              investmentSidebar: true
            }
          },
          {
            path: "/parent/investments/reports",
            exact: true,
            component: lazy(() => import("../View/post-login/ParentView/Investments/Parent/Reports")),
            isPrivate: false,
            isAuth: true,
            isMobileNav: true,
            params: {
              notificationBell: false,
              helpIcon: false,
              languageIcon: false,
              headerIcons: false,
              sidebar: false,
              investmentSidebar: true
            }
          },
          {
            path: "/parent/investments/identity",
            exact: true,
            component: lazy(() => import("../View/post-login/ParentView/Investments/Identity")),
            isPrivate: false,
            isAuth: true,
            isMobileNav: false,
            params: {
              title: 'Identity',
              notificationBell: false,
              helpIcon: false,
              languageIcon: false,
              headerIcons: false,
              sidebar: false,
            }
          },
          {
            path: "/parent/investments/employerInfo",
            exact: true,
            component: lazy(() => import("../View/post-login/ParentView/Investments/EmployerInfo")),
            isPrivate: false,
            isAuth: true,
            isMobileNav: false,
            params: {
              title: 'Employer Information',
              notificationBell: false,
              helpIcon: false,
              languageIcon: false,
              headerIcons: false,
              sidebar: false,
            }
          },

          {
            path: "/parent/investments/financial/profile",
            exact: true,
            component: lazy(() => import("../View/post-login/ParentView/Investments/FinancialProfile")),
            isPrivate: false,
            isAuth: true,
            isMobileNav: false,
            params: {
              title: 'Financial Profile',
              notificationBell: false,
              helpIcon: false,
              languageIcon: false,
              headerIcons: false,
              sidebar: false,
            }
          },
          {
            path: "/parent/investments/affliliations",
            exact: true,
            component: lazy(() => import("../View/post-login/ParentView/Investments/Affliliations")),
            isPrivate: false,
            isAuth: true,
            isMobileNav: false,
            params: {
              title: 'Affliliations',
              notificationBell: false,
              helpIcon: false,
              languageIcon: false,
              headerIcons: false,
              sidebar: false,
            }
          },
          {
            path: "/parent/investments/affliliations/QnA",
            exact: true,
            component: lazy(() => import("../View/post-login/ParentView/Investments/AffliliationsQnA")),
            isPrivate: false,
            isAuth: true,
            isMobileNav: false,
            params: {
              title: 'Affliliations',
              notificationBell: false,
              helpIcon: false,
              languageIcon: false,
              headerIcons: false,
              sidebar: false,
            }
          },
          {
            path: "/parent/investments/affliliationsForm",
            exact: true,
            component: lazy(() => import("../View/post-login/ParentView/Investments/AffiliationForm")),
            isPrivate: false,
            isAuth: true,
            isMobileNav: false,
            params: {
              title: 'Affliliations',
              notificationBell: false,
              helpIcon: false,
              languageIcon: false,
              headerIcons: false,
              sidebar: false,
            }
          },
          {
            path: "/parent/investments/terms",
            exact: true,
            component: lazy(() => import("../View/post-login/ParentView/Investments/Terms")),
            isPrivate: false,
            isAuth: true,
            isMobileNav: false,
            params: {
              title: 'Terms',
              notificationBell: false,
              helpIcon: false,
              languageIcon: false,
              headerIcons: false,
              sidebar: false,
            }
          },
          {
            path: "/parent/investments/setup",
            exact: true,
            component: lazy(() => import("../View/post-login/ParentView/Investments/Setup")),
            isPrivate: false,
            isAuth: true,
            isMobileNav: false,
            params: {
              title: 'Setup',
              notificationBell: false,
              helpIcon: false,
              languageIcon: false,
              headerIcons: false,
              sidebar: false,
            }
          },
          // {
          //   path: "/parent/child/dashboard",
          //   exact: true,
          //   component: lazy(() => import("../View/post-login/ChildView/Dashboard")),
          //   isPrivate: false,
          //   isAuth: true,
          //   isMobileNav: false,
          //   params: {
          //     title: 'Setup',
          //     notificationBell: false,
          //     helpIcon: false,
          //     languageIcon: false,
          //     headerIcons: false,
          //     sidebar: true,
          //   }
          // },
          // {
          //   path: "/parent/auth/not-found",
          //   exact: true,
          //   component: lazy(() => import("../Layouts/NotFound/NotFound")),
          //   isPrivate: false,
          //   isAuth: false
          //   // component: () => <Redirect to="/not-found-401" />,
          // },
          // {
          //   component: () => getToken() ? <Redirect to="/not-found" /> : <Redirect to="/not-found" />
          // }
        ],
      },

      {
        route: "/child",
        exact: false,
        component: AuthenticatedChildLayout,
        isPrivate: false,
        isAuth: true,
        isMobileNav: false,
        params: {
          title: '',
          notificationBell: false,
          helpIcon: false,
          languageIcon: false,
        },
        routes: [
          {
            path: "/child/dashboard",
            exact: true,
            component: lazy(() => import("../View/post-login/ChildView/Dashboard")),
            isPrivate: false,
            isAuth: true,
            isMobileNav: true,
            params: {
              title: '',
              notificationBell: false,
              helpIcon: false,
              languageIcon: false,
            }
          },
          {
            path: "/child/settings",
            exact: true,
            component: lazy(() => import("../View/post-login/ChildView/Setting")),
            isPrivate: false,
            isAuth: true,
            isMobileNav: true,
            params: {
              title: '',
              notificationBell: false,
              helpIcon: false,
              languageIcon: false,
            }
          },
          {
            path: "/child/profile",
            exact: true,
            component: lazy(() => import("../View/post-login/ChildView/EditProfile")),
            isPrivate: false,
            isAuth: true,
            isMobileNav: true,
            params: {
              title: '',
              notificationBell: false,
              helpIcon: false,
              languageIcon: false,
            }
          },
          {
            path: "/child/pay",
            exact: true,
            component: lazy(() => import("../View/post-login/ChildView/Pay")),
            isPrivate: false,
            isAuth: true,
            isMobileNav: true,
            params: {
              title: 'direct_pay',
              notificationBell: false,
              helpIcon: false,
              languageIcon: false,
            }
          },
          {
            path: "/child/changePassword",
            exact: true,
            component: lazy(() => import("../View/post-login/ChildView/Setting/ChangePassword")),
            isPrivate: false,
            isAuth: true,
            isMobileNav: false,
            params: {
              title: 'change_password',
              notificationBell: false,
              helpIcon: false,
              languageIcon: false,
            }
          },
          {
            path: "/child/chores",
            exact: true,
            component: lazy(() => import("../View/post-login/ChildView/ChoreAndGoals")),
            isPrivate: false,
            isAuth: true,
            isMobileNav: true,
            params: {
              title: '',
              notificationBell: false,
              helpIcon: false,
              languageIcon: false,
            }
          },
          {
            path: "/child/education",
            exact: true,
            component: lazy(() => import("../View/post-login/ChildView/Education")),
            isPrivate: false,
            isAuth: true,
            isMobileNav: true,
            params: {
              title: 'education',
              notificationBell: false,
              helpIcon: false,
              languageIcon: false,
            }
          },
          {
            path: "/child/education/detail/:contentId",
            exact: true,
            component: lazy(() => import("../View/post-login/ChildView/EducationDetail")),
            isPrivate: false,
            isAuth: true,
            isMobileNav: false,
            params: {
              title: '',
              notificationBell: false,
              helpIcon: false,
              languageIcon: false,
            }
          },
          {
            path: "/child/shopping",
            exact: true,
            component: lazy(() => import("../View/post-login/ChildView/Shopping")),
            isPrivate: false,
            isAuth: true,
            isMobileNav: true,
            params: {
              title: '',
              notificationBell: false,
              helpIcon: false,
              languageIcon: false,
            }
          },

          {
            path: "/child/digital/goals",
            exact: true,
            component: lazy(() => import("../View/post-login/ChildView/DigitalWallet/ChildDigitalGoals")),
            isPrivate: false,
            isAuth: true,
            isMobileNav: false,
            params: {
              title: 'goals',
              notificationBell: false,
              helpIcon: false,
              languageIcon: false,
            }
          },

          {
            path: "/child/digital/wallet",
            exact: true,
            component: lazy(() => import("../View/post-login/ChildView/DigitalWallet/ChildDigitalWallet")),
            isPrivate: false,
            isAuth: true,
            isMobileNav: false,
            params: {
              title: 'wallet',
              notificationBell: false,
              helpIcon: false,
              languageIcon: false,
            }
          },

          {
            path: "/child/digital/saving",
            exact: true,
            component: lazy(() => import("../View/post-login/ChildView/DigitalWallet/ChildDigitalSaving")),
            isPrivate: false,
            isAuth: true,
            isMobileNav: false,
            params: {
              title: 'savings',
              notificationBell: false,
              helpIcon: false,
              languageIcon: false,
            }
          },

          {
            path: "/child/investments/dashboard",
            exact: true,
            component: lazy(() => import("../View/post-login/ChildView/Investments/Dashboard")),
            isPrivate: false,
            isAuth: true,
            isMobileNav: true,
            params: {
              title: 'Investments',
              notificationBell: false,
              helpIcon: false,
              languageIcon: false,
              headerIcons: false,
              sidebar: false,
              investmentSidebar: true
            }
          },

          {
            path: "/child/investments/research",
            exact: true,
            component: lazy(() => import("../View/post-login/ChildView/Investments/Research")),
            isPrivate: false,
            isAuth: true,
            isMobileNav: true,
            params: {
              notificationBell: false,
              helpIcon: false,
              languageIcon: false,
              headerIcons: false,
              sidebar: false,
              investmentSidebar: true
            }
          },
          {
            path: "/child/investments/research-industry",
            exact: true,
            component: lazy(() => import("../View/post-login/ChildView/Investments/Research/ResearchDetail")),
            isPrivate: false,
            isAuth: true,
            isMobileNav: true,
            params: {
              notificationBell: false,
              helpIcon: false,
              languageIcon: false,
              headerIcons: false,
              sidebar: false,
              investmentSidebar: true
            }
          },
          {
            path: "/child/investments/watchlist",
            exact: true,
            component: lazy(() => import("../View/post-login/ChildView/Investments/Watchlist")),
            isPrivate: false,
            isAuth: true,
            isMobileNav: true,
            params: {
              notificationBell: false,
              helpIcon: false,
              languageIcon: false,
              headerIcons: false,
              sidebar: false,
              investmentSidebar: true
            }
          },
          {
            path: "/child/investments/reports",
            exact: true,
            component: lazy(() => import("../View/post-login/ChildView/Investments/Reports")),
            isPrivate: false,
            isAuth: true,
            isMobileNav: true,
            params: {
              notificationBell: false,
              helpIcon: false,
              languageIcon: false,
              headerIcons: false,
              sidebar: false,
              investmentSidebar: true
            }
          },

          {
            path: "/child/investments/buy-assets",
            exact: true,
            component: lazy(() => import("../View/post-login/ChildView/Investments/BuyAssets")),
            isPrivate: false,
            isAuth: true,
            isMobileNav: true,
            params: {
              notificationBell: false,
              helpIcon: false,
              languageIcon: false,
              headerIcons: false,
              sidebar: false,
              investmentSidebar: true
            }
          },
          {
            path: "/child/investments/timeline",
            exact: true,
            component: lazy(() => import("../View/post-login/ChildView/Investments/Timeline")),
            isPrivate: false,
            isAuth: true,
            isMobileNav: true,
            params: {
              title: 'Timeline',
              notificationBell: false,
              helpIcon: false,
              languageIcon: false,
              headerIcons: false,
              sidebar: false,
              investmentSidebar: true
            }
          },
          {
            path: "/child/investments/portfolio-detail",
            exact: true,
            component: lazy(() => import("../View/post-login/ChildView/Investments/PortfolioDetail")),
            isPrivate: false,
            isAuth: true,
            isMobileNav: true,
            params: {
              notificationBell: false,
              helpIcon: false,
              languageIcon: false,
              headerIcons: false,
              sidebar: false,
              investmentSidebar: true
            }
          },
          {
            path: "/auth/not-found",
            exact: true,
            component: lazy(() => import("../Layouts/NotFound/NotFound")),
            isPrivate: false,
            isAuth: false
            // component: () => <Redirect to="/not-found-401" />,
          },
          {
            component: () => getToken() ? <Redirect to="/not-found" /> : <Redirect to="/not-found" />
          }
        ]
      },

      {
        path: "/not-found",
        exact: true,
        component: lazy(() => import("../Layouts/NotFound/NotFound")),
        isPrivate: true,
      },

      // {
      //   component: () => <Redirect to="/not-found" />
      // },
    ],

  },

];

export default routes;
